import styled from '@emotion/styled';
import React from 'react';
import axios from 'axios';
import { useAppData } from '../../contexts/AppDataProvider';
import useSWR from 'swr';
import Track from '../../shared/Track';
import ButtonWithArrow from '../../shared/ui/ButtonWithArrow';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { LeftContent, RightContent, StyledContainer } from './cardstyles';
import { StyledButton } from '../../shared/ui/Button';

const StyledDashboardCard = styled(StyledContainer)`
  background: #ff8582;
  color: white;
  &.no-meeting {
    padding: 0px;
  }
`;
const StyledRightContent = styled(RightContent)`
  &.no-meeting {
    background: url('/session-illustration-pink.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 50%;
    padding: 44px 24px 44px 0px;
  }
`;
const StyledLeftContent = styled(LeftContent)`
  width: 50%;
  &.no-meeting {
    padding: 44px 0px 44px 24px;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);

const SessionsCard = () => {
  const { data } = useSWR('/api/v2/meetings', fetcher);
  const { companyID } = useAppData();
  const history = useHistory();
  const bookSession = () => {
    Track.bookASession('Dashboard');
    if (!companyID && sessions_left <= 0) {
      window.location = '/individual/dashboard/packages';
    } else {
      history.push('/book_session');
    }
  };

  if (data === undefined) {
    return <></>;
  }
  const nearest_meeting = data.nearest_meeting;

  const { sessions_left, sessions_count_visible } = data;
  return (
    <>
      <StyledDashboardCard
        className={`${!nearest_meeting?.date ? 'no-meeting' : ''}`}>
        <StyledLeftContent
          className={`${!nearest_meeting?.date ? 'no-meeting' : ''}`}>
          {sessions_count_visible === true && (
            <h6>
              You have{' '}
              {sessions_left === 1
                ? `${sessions_left || 0} session`
                : `${sessions_left || 0} sessions`}{' '}
              available to book.
            </h6>
          )}
          <p>Reach out to our counselors or coaches.</p>
        </StyledLeftContent>
        <StyledRightContent
          className={`${!nearest_meeting?.date ? 'no-meeting' : ''}`}>
          {nearest_meeting?.date ? (
            <ButtonWithArrow
              variant="white"
              onClick={bookSession}
              text="Book a Session"
            />
          ) : (
            <>
              <StyledButton className="white fit-content" onClick={bookSession}>
                Book a Session
              </StyledButton>
            </>
          )}
        </StyledRightContent>
      </StyledDashboardCard>
    </>
  );
};

export default SessionsCard;
