import React from 'react';
import { formatDateTime } from '../../../shared/utils/formatDateTime';

const SelectedSessionText = ({
  selectedDateSlotType,
  selectedMeetingSetup
}) => {
    const tempdateString = formatDateTime(
      selectedDateSlotType.date.toISOString(),
      'EEEE, MMMM dd, yyyy',
    );
    const selectedTimeString = formatDateTime(
      new Date(selectedDateSlotType?.slot?.start_time).toISOString(),
      'h:mm a',
    );
    const meeting_setup = selectedMeetingSetup === 'on_site' ? 'In-Person' : 'Virtual';
  return <p className='text-center'><strong>{meeting_setup}</strong>{` session on ${tempdateString} at ${selectedTimeString}`}</p>;
};

export default SelectedSessionText;
