import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-bootstrap';

export const StyledToolTip = styled(Tooltip)`
  .tooltip-inner {
    font-family: 'Spartan', sans-serif;
    text-align: left;
    font-size: 12px;
    color: black;
    max-width: 275px;
    padding: ${({ theme }) => theme.spacing.s};
    white-space: pre-line;
    border-radius: ${({ theme }) => theme.radius.default};
    border: ${({ theme }) => theme.colors.borderDefault} 1px solid;
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 4px 8px 0px rgba(47, 64, 87, 0.02),
      0px 0px 8px 0px rgba(47, 64, 87, 0.05);
    .header{
      font-size: 14px;
    }
  }
`;
