import React from 'react';
import styled from '@emotion/styled';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import UIProfileImage from '../../../shared/ui/ProfileImage';
import { StyledName } from '../BookingDone';
import { StyledToolTip } from '../../../shared/ui/StyledTooltip';

const CounselorContainer = styled.div`
  position: relative;
  margin: auto;
  margin-top: 20px;
  margin-top: 20px;
  max-width: 662px;
  display: flex;
  align-items: center;
  gap: 10px;
  .professional-image {
    width: 30px;
    height: 30px;
  }
  .name {
    font-weight: 700;
  }
`;

const ProfessionalInfo = ({ professional, onClick }) => {
  const renderTooltip = (props) => (
    <StyledToolTip id="why-you-are-matched-tooltip" {...props}>
      <span>
        <strong className='header'>Why were you matched with this counselor?</strong>
      </span>
      <br />
      <span>
        We matched you with the most suitable counselor based on your needs and
        the counselors&apos; availability at your selected date and time.
      </span>
    </StyledToolTip>
  );

  return (
    <>
      <CounselorContainer>
        <UIProfileImage
          src={professional?.avatar_url || '/coaching/profile.png'}
          className="professional-image"
        />
        <StyledName className="name d-block" onClick={onClick}>
          {professional?.full_name}
        </StyledName>
        {professional?.title && (
          <span className="title d-block">{professional?.title}</span>
        )}
        <OverlayTrigger placement="right" overlay={renderTooltip}>
          <Image src="info-circle.svg" />
        </OverlayTrigger>
      </CounselorContainer>
    </>
  );
};

export default ProfessionalInfo;
