import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Image, OverlayTrigger } from 'react-bootstrap';
import { StyledToolTip } from '../../../shared/ui/StyledTooltip';

const StyledInfo = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textBodyLight};
`;

const TimeSlotHeader = ({ type = 'Preference', selectedSession }) => {
  const [preferences, setPreferences] = useState([]);
  const fetchPreferences = async () => {
    const res = await fetch('/api/v2/preferences');
    if (res.status == 200) {
      const data = await res.json();
      setPreferences(data);
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  const renderTooltip = (props) => (
    <StyledToolTip id="preference-tooltip" {...props}>
      <strong className='header'>Your Preferences:</strong>
      <br />
      Gender: {preferences?.gender},
      <br />
      Language: {preferences?.language}
    </StyledToolTip>
  );

  return (
    <>
      {type === 'Preference' && (
        <>
          <strong className="mr-1">
            {selectedSession == 'counseling' ? 'Counselor' : 'Coaches'} Based on
            your Preferences
          </strong>
          <OverlayTrigger placement="left" overlay={renderTooltip}>
            <Image src="info-circle.svg" />
          </OverlayTrigger>
        </>
      )}
      {type === 'Other' && (
        <>
          {' '}
          <strong>
            Any available{' '}
            {selectedSession === 'counseling' ? 'counselor' : 'coach'}
          </strong>
          <br />
          <StyledInfo>These may not fully match your preferences.</StyledInfo>
        </>
      )}
    </>
  );
};

export default TimeSlotHeader;
