import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Image } from 'react-bootstrap';

const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.textBodyDefault};
  border-radius: ${({ theme }) => theme.radius.radiusRound};
  border: ${({ theme }) => `2px solid ${theme.colors.borderDefault}`};
  padding: 12px 16px;
  width: fit-content;
  display: flex;
  gap: 4px;
  cursor: pointer;
  &.white {
    background-color: ${({ theme }) => theme.colors.textWhite};
    border-radius: ${({ theme }) => theme.colors.textWhite};
  }
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.brandPrimaryDefault};
    border: ${({ theme }) => `2px solid ${theme.colors.brandPrimaryDefault}`};
    background-color: ${({ theme }) => theme.colors.surfaceSubtleEmphasis};
  }
  &.active {
    font-weight: 700;
  }
  span {
    font-size: 14px;
  }
`;

const StyledImage = styled(Image)`
  width: 24px;
  height: 24px;
`;

const Pill = (props) => {
  const [src, setSrc] = useState(props.image);

  useEffect(() => {props.active ? setSrc(props.activeImage) : setSrc(props.image)}, [props.active]);
  return (
    <StyledButton
      {...props}
      onMouseEnter={() =>
        props.activeImage && !props.active ? setSrc(props.activeImage) : null
      }
      onMouseLeave={() => (props.image && !props.active ? setSrc(props.image) : null)}>
      {props.image && (
        <StyledImage
          src={src}
        />
      )}
      <span>{props.text}</span>
    </StyledButton>
  );
};

export default Pill;
