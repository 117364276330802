import React, { useState } from 'react';
import styled from '@emotion/styled';
import { formatDateTime } from '../../../shared/utils/formatDateTime';
import { Pill } from '../../../shared/ui';
import { useBookSessionData } from '../../../contexts/BookSessionDataProvider';
import { Image } from 'react-bootstrap';

const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.textBodyDefault};
  border-radius: 24px;
  border: ${({ theme }) => `2px solid ${theme.colors.borderDefault}`};
  padding: 12px 16px;
  width: fit-content;
  display: flex;
  gap: 4px;
  cursor: pointer;
  &.white {
    background-color: ${({ theme }) => theme.colors.textWhite};
    border: ${({ theme }) => `2px solid ${theme.colors.textWhite}`};
  }
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.brandPrimaryDefault};
    border: ${({ theme }) =>
      `2px solid ${theme.colors.brandPrimaryDefault}`};
    background-color: ${({ theme }) =>
      theme.colors.surfaceSubtleEmphasis};
  }
  &.active {
    font-weight: 700;
  }
  span {
    font-size: 14px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  .meeting-setup {
    font-size: 12px;
  }
`;

const HybridButton = styled(StyledButton)`
  &:hover {
    .chevron-image {
      background-image: url('chevron-up-active-state.svg');
    }
  }
  &.selected{
    .chevron-image {
      background-image: url('chevron-up-active-state.svg') !important;
    }
  }
  &.open-slot {
    .chevron-image {
      background-image: url('chevron-up.svg') !important;
    }
    .selected {
      background-image: url('chevron-up.svg') !important;
    }
    color: ${({ theme }) => theme.colors.textBodyDefault} !important;
    border: ${({ theme }) => `2px solid ${theme.colors.borderDefault}`}!important;
    background-color: ${({ theme }) => theme.colors.textWhite}!important;
    font-weight: 400 !important;
  }
  &.selected, &:hover {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.brandPrimaryDefault};
    border: ${({ theme }) =>
      `2px solid ${theme.colors.brandPrimaryDefault}`};
    background-color: ${({ theme }) => theme.colors.surfaceSubtleEmphasis};
  }
`;

const HybridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  .meeting-setup {
    font-size: 12px;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .chevron-image {
    width: 24px;
    height: 24px;
    background-image: url('chevron-up.svg');
    background-size: cover;
    transform: rotate(90deg);
  }
  .chevron-image.rotate {
    transform: rotate(0deg);
  }
`;

const ArrangementContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 32px;
`;

const ArrangementHeader = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: left;
  margin-top: 20px;
`;

const SetupChevronContainer = styled.div`
  display: flex;
`;

const TimeSlots = ({
  type,
  timeSlots,
  toggleTimeSlot,
  selectedArrangement,
}) => {
  const {
    setSelectedMeetingSetup,
    selectedMeetingSetup,
    selectedDateSlotType,
  } = useBookSessionData();
  const [openSlot, setOpenSlot] = useState(false);
  const [hybridTimeSlot, setHybridTimeSlot] = useState(null);

  return timeSlots.map((object, index) => (
    <>
      {selectedArrangement !== 'hybrid' && (
        <StyledButton
          key={index}
          className={`mb-2 d-block w-100 white ${
            selectedDateSlotType?.slot?.start_time === object?.start_time &&
            selectedDateSlotType?.type === type
              ? 'active'
              : ''
          }`}
          onClick={() => {
            toggleTimeSlot(object, type);
          }}>
          <TextContainer>
            <span>{formatDateTime(object.start_time, 'h:mm a')}</span>
          </TextContainer>
        </StyledButton>
      )}
      {selectedArrangement === 'hybrid' &&
        object.meeting_setup !== 'hybrid' && (
          <StyledButton
            key={index}
            className={`mb-2 d-block w-100 white ${
              selectedDateSlotType?.slot?.start_time === object?.start_time &&
              selectedDateSlotType?.type === type
                ? 'active'
                : ''
            }`}
            onClick={() => {
              toggleTimeSlot(object, type);
              setSelectedMeetingSetup(object.meeting_setup);
            }}>
            <TextContainer>
              <span>{formatDateTime(object.start_time, 'h:mm a')}</span>
              {object.meeting_setup === 'virtual' && (
                <span className="meeting-setup">Virtual only</span>
              )}
              {object.meeting_setup === 'on_site' && (
                <span className="meeting-setup">In-Person only</span>
              )}
            </TextContainer>
          </StyledButton>
        )}

      {selectedArrangement === 'hybrid' &&
        object.meeting_setup === 'hybrid' && (
          <HybridButton
            key={index}
            className={`mb-2 d-block w-100 white ${
              selectedDateSlotType?.slot?.start_time === object?.start_time
                ? 'selected'
                : ''
            } ${openSlot ? 'open-slot' : ''}`}
            onClick={() => {
              if (hybridTimeSlot?.start_time === object?.start_time) {
                setOpenSlot(!openSlot);
              } else {
                setOpenSlot(true);
              }
              setHybridTimeSlot(object);
            }}>
            <HybridContainer>
              <TimeContainer>
                <span className="time">
                  {formatDateTime(object.start_time, 'h:mm a')}
                </span>
                <SetupChevronContainer>
                  {selectedDateSlotType?.slot?.start_time ===
                    object?.start_time &&
                    selectedMeetingSetup && (
                      <span className="mr-2">
                        {selectedMeetingSetup === 'on_site'
                          ? 'In-Person'
                          : 'Virtual'}
                      </span>
                    )}

                  <div
                    className={
                      `${
                        selectedDateSlotType?.slot?.start_time ===
                        object?.start_time
                          ? 'active chevron-image'
                          : 'chevron-image'
                      }` +
                      `${
                        hybridTimeSlot?.start_time === object?.start_time && openSlot == true
                          ? ' rotate'
                          : ''
                      }`
                    }
                  />
                </SetupChevronContainer>
              </TimeContainer>
              {hybridTimeSlot?.start_time === object?.start_time &&
                openSlot && (
                  <div>
                    <ArrangementHeader>
                      Select Arrangements
                    </ArrangementHeader>
                    <ArrangementContainer>
                      <Pill
                        image="friends.svg"
                        activeImage="friends-active-state.svg"
                        text="In-Person"
                        active={
                          selectedDateSlotType?.slot?.start_time ===
                            object?.start_time &&
                          selectedMeetingSetup === 'on_site'
                        }
                        className={`white ${
                          selectedDateSlotType?.slot?.start_time ===
                            object?.start_time &&
                          selectedMeetingSetup === 'on_site'
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => {
                          toggleTimeSlot(object, type);
                          setSelectedMeetingSetup('on_site');
                        }}
                      />
                      <Pill
                        image="camera-video.svg"
                        activeImage="camera-video-active-state.svg"
                        text="Virtual"
                        active={
                          selectedDateSlotType?.slot?.start_time ===
                            object?.start_time &&
                          selectedMeetingSetup === 'virtual'
                        }
                        className={`white ${
                          selectedDateSlotType?.slot?.start_time ===
                            object?.start_time &&
                          selectedMeetingSetup === 'virtual'
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => {
                          toggleTimeSlot(object, type);
                          setSelectedMeetingSetup('virtual');
                        }}
                      />
                    </ArrangementContainer>
                  </div>
                )}
            </HybridContainer>
          </HybridButton>
        )}
    </>
  ));
};

export default TimeSlots;
