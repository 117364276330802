import axios from 'axios';
import useSWR from 'swr';
import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';
import { openChat } from '../../../packs/freshchat';
import TakeAssessmentCard from './components/TakeAssessmentCard';
import AssessmentResultCard from './components/AssessmentResultCard';
import StyledSubmitButton from '../shared/StyledSubmitButton';
import MeetingCancel from '../Meetings/Cancel';
import { formatDateTime } from '../shared/utils/formatDateTime';
import ProfessionalImageComponent from './components/ProfessionalImageComponent';

const StyledWelcome = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 25px;
`;

const CapitalizedHeading = styled.h4`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #7e84a3;
`;

const StyledLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.brandPrimaryDefault};
  font-weight: 700;
  :hover {
    color: ${({ theme }) => theme.colors.brandPrimaryDefault};
  }
`;

const CoachingSession = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  .heading {
    font-size: 14px;
    margin-bottom: 0px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.textBodyDefault};
    font-weight: 700;
  }
  .time {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.textBodyLight};
    display: block;
    margin-top: 3px;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Sessions = () => {
  const { data: upcomingMeetings } = useSWR(
    '/api/v2/meetings?upcoming=true',
    fetcher,
  );
  const { firstName, access_to_assessment, withChat, currentAssessment } =
    useAppData();

  return (
    <>
      <Row>
        <Col>
          <StyledWelcome>Hello, {firstName}!</StyledWelcome>
        </Col>
      </Row>
      {access_to_assessment && (
        <Row>
          <Col className="my-3">
            {currentAssessment ? (
              <AssessmentResultCard />
            ) : (
              <TakeAssessmentCard />
            )}
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          {upcomingMeetings?.length > 1 ? (
            <>
              <CapitalizedHeading>Other upcoming sessions</CapitalizedHeading>
              {upcomingMeetings.slice(1).map((meeting) => (
                <CoachingSession key={meeting.id}>
                  <ProfessionalImageComponent meeting={meeting} />
                  <div>
                    <h6 className="heading">
                      {meeting.setup === 'virtual' ? 'Virtual' : 'Onsite'}{' '}
                      {meeting.purpose}
                    </h6>
                    <span className="time">
                      {`${formatDateTime(
                        meeting?.meeting_date,
                        'EEEE',
                      )}, ${formatDateTime(meeting?.start_time, 'h:mm a')}`}
                    </span>
                    <span className="time">
                      {formatDateTime(meeting?.meeting_date, 'MMMM dd, yyyy')}
                    </span>
                  </div>

                  <MeetingCancel
                    meeting={meeting}
                    style={{ fontSize: '12px' }}
                  />
                </CoachingSession>
              ))}
            </>
          ) : (
            <>
              <CapitalizedHeading>Other upcoming sessions</CapitalizedHeading>
              <p className="mb-1">No other upcoming sessions</p>
            </>
          )}
        </Col>
      </Row>

      <Row>
        <Col className="mb-3">
          <StyledLink href="/individual/dashboard/session_history">
            <img className="pb-1 pr-2" src="/session-history.svg" />
            See session history
          </StyledLink>
        </Col>
      </Row>
      {withChat && (
        <Row>
          <Col>
            <StyledSubmitButton onClick={openChat}>
              Start a Live Chat
            </StyledSubmitButton>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Sessions;
