import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { StyledHeader, Selection } from './styles';
import { UIBackButton } from '../../shared/ui/Back';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import Track from '../../shared/Track';
import NextButton from '../../shared/ui/NextButton';
import styled from '@emotion/styled';

const StyledRow = styled(Row)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const SelectReasons = ({ reasons }) => {
  const {
    currentStep,
    setCurrentStep,
    selectedReasons,
    setSelectedReasons,
    setPreviousStep,
    previousStep,
  } = useBookSessionData();
  const toggleReason = (reasonObject) => {
    if (selectedReasons.find((object) => object.id == reasonObject.id)) {
      setSelectedReasons(
        selectedReasons.filter((object) => object.id !== reasonObject.id),
      );
    } else {
      selectedReasons.push(reasonObject);
      let newselectedReasons = [...new Set(selectedReasons)];
      setSelectedReasons(newselectedReasons);
    }
  };

  useEffect(() => {
    Track.pageView({
      page: 'Preference Page',
      purpose: 'coaching',
    });
  }, []);

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep('select_session')} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>What is your reason? Select all that apply.</h3>
      </StyledHeader>
      <StyledRow>
        {reasons.map((obj, index) => (
          <Col lg={6} md={12} key={index}>
            <Selection
              className={`${
                selectedReasons.find(
                  (reasonObject) => reasonObject.id == obj.id,
                )
                  ? 'active'
                  : ''
              } ${index % 2 == 0 ? 'even' : 'odd'}`}
              onClick={() => toggleReason(obj)}>
              {obj.title}
            </Selection>
          </Col>
        ))}
      </StyledRow>
      <Row className="justify-content-center">
        <NextButton
          type="button"
          className="mt-2"
          text={`Select Date & Time`}
          onClick={() => {
            setPreviousStep(currentStep);
            setCurrentStep('select_date_time');
          }}
          disabled={selectedReasons.length == 0}
        />
      </Row>
    </>
  );
};
export default SelectReasons;
