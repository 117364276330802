import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { UIBackButton } from '../../shared/ui/Back';
import { useEffect } from 'react';
import { StyledHeader } from './styles';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import Track from '../../shared/Track';
import Spinner from '../../../Spinner';
import NextButton from '../../shared/ui/NextButton';
import Counselors from './components/Counselors';
import { formatDateTime } from '../../shared/utils/formatDateTime';
import { useAppData } from '../../contexts/AppDataProvider';
import Arrangements from './components/Arrangements';
import TimeSlotHeader from './components/TimeSlotsHeader';
import TimeSlots from './components/TimeSlots';
import SelectedSessionText from './components/SelectedSessionText';

const CalendarContainer = styled.div`
  background-color: #fcfcfc;
  border-radius: ${({ theme }) => theme.radius.default};
  display: flex;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 40px;
  justify-content: center;
  width: fit-content;
  /* Elevation/Mid */
  box-shadow: 0px 8px 16px 0px rgba(31, 41, 55, 0.06),
    0px 0px 8px 0px rgba(31, 41, 55, 0.05);
  @media (max-width: 767px) {
    flex-direction: column;
  }
  h6 {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
`;
const DateAndTime = styled.div`
  flex: 1 1 0;
  width: 0;
  min-width: 400px;
  max-width: 400px;
  height: auto;
  position: relative;
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .left-radius {
    border-top-left-radius: ${({ theme }) => theme.radius.default};
    border-bottom-left-radius: ${({ theme }) => theme.radius.default};
  }
  @media (max-width: 991px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  background: ${({ theme }) => theme.colors.surfaceContrast};
  padding: 32px;
  button[name='day'] {
    background-color: #ffffff;
    color: ${({ theme }) => theme.colors.textBodyDefault};
    font-weight: 500;
  }
  button[name='day'].rdp-day_outside {
    color: ${({ theme }) => theme.colors.textBodyDefault};
    font-weight: 400;
    opacity: 0.2;
  }
  button.rdp-day_selected {
    background-color: ${({ theme }) => theme.colors.surfaceSubtleEmphasis};
    color: ${({ theme }) => theme.colors.brandPrimaryDefault};
    border: ${({ theme }) => `${theme.colors.brandPrimaryDefault} 1px solid`};
    font-weight: 700;
  }
  .rdp {
    margin: 0px !important;
  }
  @media (max-width: 767px) {
    .rdp {
      width: 80%;
      margin: auto;
    }
  }
  @media (max-width: 500px) {
    .rdp {
      width: 100%;
    }
  }
  .rdp-table {
    th,
    td {
      border: 8px solid transparent;
    }
  }
  .rdp-nav_button {
    color: #1db4b3;
  }
  .rdp-caption_label {
    font-weight: 700;
    font-size: 14px;
  }
  .rdp-head_cell {
    font-weight: 700;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textBodyLight};
  }
`;
const Schedules = styled.div`
  padding: 30px 30px;
  flex: 1 1 0;
  width: 0;
  max-width: 400px;
  min-width: 400px;
  @media (max-width: 991px) {
    width: 40%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0px;
    margin-top: 40px;
  }
  background-color: ${({ theme }) => theme.colors.surfaceDefault};
  text-align: center;
`;
const TimeSlotContainer = styled.div`
  max-height: 514px;
  overflow: auto;
  text-align: left;
`;
const CalendarInfo = styled.p`
  margin-top: 24px;
  font-size: 12px;
`;
const NextButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CalendarSessions = () => {
  const {
    selectedSession,
    setCurrentStep,
    setSelectedDateSlotType,
    setSelectedMeetingSetup,
    selectedMeetingSetup,
    selectedDateSlotType,
  } = useBookSessionData();
  const { meetingSetup } = useAppData();
  const [selectedCounselor, setSelectedCounselor] = useState(null);
  const [disabledDates, setDisabledDates] = useState([]);
  const [dateForAvailability, setDateForAvailability] = useState(new Date());
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);
  const [previousProfessionals, setPreviousProfessionals] = useState([]);
  const [previousProfessionalsAvailable, setPreviousProfessionalsAvailable] =
    useState(null);
  const [selectedArrangement, setSelectedArrangement] = useState(meetingSetup);
  const [slotResponse, setSlotResponse] = useState({
    dates: [],
    prev_professionals: [],
    professional_slots: [],
    preference_slots: [],
    other_slots: [],
  });
  const prevStep =
    selectedSession === 'coaching' ? 'select_reasons' : 'select_session';
  const professional = selectedSession === 'coaching' ? 'coach' : 'counselor';
  const setDateAndRemoveSlot = (date) => {
    if (!date) {
      return;
    }
    setSelectedDateSlotType({
      ...selectedDateSlotType,
      date,
      slot: null,
    });
  };
  const toggleTimeSlot = (timeSlot, type) => {
    timeSlot['professional'] =
      timeSlot['professional'] || timeSlot['professional_id'];
    setSelectedDateSlotType({
      ...selectedDateSlotType,
      slot: timeSlot,
      type,
    });
  };

  const fetchAvailableDates = async () => {
    setLoadingDates(true);
    const tempdateString = formatDateTime(
      dateForAvailability.toISOString(),
      'yyyy-MM-dd',
    );

    const params = {
      date: tempdateString,
      purpose: selectedSession || 'counseling',
      duration: 'month',
      meeting_setup: selectedArrangement,
      return_prev_professionals: true,
      return_dates: true,
    };

    if (selectedCounselor?.id) {
      params['professional_id'] = selectedCounselor?.id;
    }
    const queryString = new URLSearchParams(params).toString();
    let url = `/api/v3/availabilities/slots/?${queryString}`;
    const res = await fetch(url);

    if (res.status === 200) {
      const body = await res.json();

      const dates = body.dates.map((date) => new Date(date).toDateString());

      const disabledDates = getAllDatesInMonth(
        dateForAvailability.getFullYear(),
        dateForAvailability.getMonth(),
      ).filter((date) => !dates.includes(date.toDateString()));

      setDisabledDates(disabledDates);
      setPreviousProfessionalsAvailable(body.prev_professionals);
    }
    setLoadingDates(false);
  };

  function getAllDatesInMonth(year, month) {
    // Month is 0-based (0 = January, 11 = December)
    const date = new Date(year, month, 1);
    const dates = [];

    // Loop through the days of the month
    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1); // Move to the next day
    }

    return dates;
  }

  const tempFetchTimeSlotsOnSelectedDate = async () => {
    setLoadingTimeSlots(true);
    const tempdateString = formatDateTime(
      selectedDateSlotType.date.toISOString(),
      'yyyy-MM-dd',
    );

    const params = {
      date: tempdateString,
      purpose: selectedSession || 'counseling',
      duration: 'day',
      meeting_setup: selectedArrangement,
    };

    if (selectedCounselor?.id) {
      params['professional_id'] = selectedCounselor?.id;
    }

    const queryString = new URLSearchParams(params).toString();
    let url = `/api/v3/availabilities/slots/?${queryString}`;
    const res = await fetch(url);

    if (res.status === 200) {
      const body = await res.json();
      setSlotResponse(body);
      setLoadingTimeSlots(false);
      setSelectedDateSlotType({
        ...selectedDateSlotType,
        slot: null,
        type: null,
      });
    }
  };

  const fetchPreviousProfessionals = async () => {
    const params = {
      previous: true,
      purpose: selectedSession || 'counseling',
    };
    const queryString = new URLSearchParams(params).toString();
    let url = `/api/v3/professionals/?${queryString}`;
    const res = await fetch(url);

    if (res.status === 200) {
      const body = await res.json();
      setPreviousProfessionals(body);
    }
  };

  useEffect(() => {
    Track.pageView({
      page: 'Calendar',
      purpose: selectedSession,
    });
    fetchPreviousProfessionals();
  }, []);

  useEffect(() => {
    if (selectedArrangement != 'hybrid') {
      setSelectedMeetingSetup(selectedArrangement);
    }
  }, [selectedArrangement]);

  useEffect(() => {
    if (selectedDateSlotType?.date) {
      tempFetchTimeSlotsOnSelectedDate();
    }
  }, [selectedDateSlotType.date, selectedArrangement, selectedCounselor]);

  useEffect(() => {
    fetchAvailableDates();
  }, [selectedArrangement, selectedCounselor, dateForAvailability]);

  const handleMonthChange = (date) => {
    setDateForAvailability(date);
  };

  const NewTimeSlotViewV2 = () => {
    if (loadingTimeSlots) {
      return (
        <div className="d-flex justify-content-center h-100">
          <Spinner />
        </div>
      );
    }
    if (!selectedDateSlotType?.date) {
      return (
        <>
          <CalendarInfo>Please select a date</CalendarInfo>
        </>
      );
    } else if (
      slotResponse.other_slots.length === 0 &&
      slotResponse.preference_slots.length === 0 &&
      slotResponse.professional_slots.length === 0
    ) {
      return (
        <>
          <CalendarInfo>No time slots available</CalendarInfo>
        </>
      );
    } else {
      return (
        <>
          {slotResponse?.preference_slots?.length > 0 && (
            <>
              <CalendarInfo>
                <TimeSlotHeader
                  type="Preference"
                  selectedSession={selectedSession}
                />
              </CalendarInfo>
              <TimeSlots
                type="Preference"
                timeSlots={slotResponse?.preference_slots}
                toggleTimeSlot={toggleTimeSlot}
                selectedArrangement={selectedArrangement}
              />
            </>
          )}
          {slotResponse?.other_slots?.length > 0 && (
            <>
              <CalendarInfo>
                <TimeSlotHeader
                  type="Other"
                  selectedSession={selectedSession}
                />
              </CalendarInfo>
              <TimeSlots
                type="Other"
                timeSlots={slotResponse?.other_slots}
                toggleTimeSlot={toggleTimeSlot}
                selectedArrangement={selectedArrangement}
              />
            </>
          )}
          {slotResponse?.professional_slots?.length > 0 && (
            <>
              <TimeSlots
                type="Professional"
                timeSlots={slotResponse?.professional_slots}
                toggleTimeSlot={toggleTimeSlot}
                selectedArrangement={selectedArrangement}
              />
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep(prevStep)} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>When would you like to have your session?</h3>
        <p className="mb-0">
          Choose your preferred arrangement, date, and time and we&apos;ll match
          you with the right {professional}.
        </p>
      </StyledHeader>
      <Row>
        <Col>
          <CalendarContainer>
            {previousProfessionals.length > 0 &&
              previousProfessionalsAvailable && (
                <Counselors
                  meetingSetup={meetingSetup}
                  previousProfessionals={previousProfessionals}
                  selectedCounselor={selectedCounselor}
                  setSelectedCounselor={setSelectedCounselor}
                  selectedArrangement={selectedArrangement}
                  setSelectedArrangement={setSelectedArrangement}
                  availablePrevProfessionals={previousProfessionalsAvailable}
                />
              )}
            <DateAndTime
              className={`${
                previousProfessionals.length === 0 ? 'left-radius' : ''
              }`}>
              {meetingSetup === 'hybrid' &&
                previousProfessionals.length === 0 && (
                  <Arrangements
                    selectedArrangement={selectedArrangement}
                    setSelectedArrangement={setSelectedArrangement}
                  />
                )}
              {loadingDates && (
                <div className="spinner">
                  <Spinner />
                </div>
              )}
              <DayPicker
                mode="single"
                onSelect={setDateAndRemoveSlot}
                fromDate={new Date()}
                showOutsideDays={false}
                className={'mt-3 calendar'}
                selected={selectedDateSlotType?.date}
                defaultMonth={selectedDateSlotType?.date}
                disabled={
                  !loadingDates
                    ? disabledDates
                    : [
                        { before: new Date(9999, 11, 31) }, // Disable all dates
                      ]
                }
                onMonthChange={handleMonthChange}
              />
            </DateAndTime>

            <Schedules>
              <TimeSlotContainer className="mb-3 h-100">
                <NewTimeSlotViewV2 />
              </TimeSlotContainer>
            </Schedules>
          </CalendarContainer>
          {selectedDateSlotType?.slot && selectedMeetingSetup && (
            <SelectedSessionText
              selectedDateSlotType={selectedDateSlotType}
              selectedMeetingSetup={selectedMeetingSetup}
            />
          )}
          <NextButtonContainer>
            <NextButton
              type="button"
              className="mt-2"
              text={`Review`}
              onClick={() => setCurrentStep('review_session')}
              disabled={!selectedDateSlotType.slot || !selectedMeetingSetup}
            />
          </NextButtonContainer>
        </Col>
      </Row>
    </>
  );
};
export default CalendarSessions;
